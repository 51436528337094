
.create-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .success-content {
        margin-top: 155px;
        text-align: center;
        display: flex;
        justify-content: center;
        .iconfont {
            color: #1fd18c;
            font-size: 52px;
        }
        .text {
            font-size: 24px;
            font-weight: bold;
            display: flex;
            align-items: center;
        }
    }
    .btn-blue-line-all {
        margin-top: 50px;
    }
}
