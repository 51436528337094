
.live-market-step-two {
  padding: 20px 20px 60px 20px;
  .btn-footer {
    position: absolute;
    bottom: -60px;
    left: 0;
    right: 0;
    text-align: center;
  }
  :deep .el-button--primary {
    background-color: #1122d8;
    border: #1122d8;
  }
  :deep .el-form-item__label {
    float: none;
  }
}
