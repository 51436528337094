
.container-box {
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .content {
        flex: 1;
        padding-top: 30px;
        padding-left: 100px;
    }
    .bottom-btn {
        text-align: center;
    }
}
.onstep-inpue {
    width: 500px;
}
.onstep-select {
    width: 500px;
}
.uoload-box {
    display: flex;
    flex-direction: row;
    .el-upload__tip {
        margin-left: 10px;
    }
}
.upload-demo {
    width: 500px;
}
//样式，去掉过渡动画
/deep/ .el-list-enter-active,
/deep/ .el-list-leave-active {
    transition: none;
}
/deep/ .el-upload-list {
    height: 40px;
}
